import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import { messages } from './components/common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import './assets/css/icon.css';
import './components/common/directives';
import 'babel-polyfill';

import infoapi from './api/manageApiConfig'
import totalmanage from './api/getapi/totalmanage'

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(ElementUI, {
    size: 'small'
});
const i18n = new VueI18n({
    locale: 'zh',
    messages
});

function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;

}

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    var url = window.location.href;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.split("?")[1];
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    if(theRequest.token){
        localStorage.setItem('ms_token',theRequest.token)
    }
    let token = localStorage.getItem('ms_token') || getQueryString('token') || theRequest.token;
    if (token) {
        let url = '/admin/info?token=' + token
        infoapi.get(url)
            .then(function (res) {
                if (res.data.status_code == 200) {
                    localStorage.setItem('ms_token', token);
                    localStorage.setItem('ms_nickname', res.data.data.nickname);
                    localStorage.setItem('ms_role', res.data.data.role_id);
                    localStorage.setItem('ms_id', res.data.data.id);
                    if(getQueryString('token')){
                        window.location.href = window.location.origin + window.location.pathname+"?t=1"
                    }else{
                        next()
                    }
                }else if(res.data.status_code == 10004){
                    if(getQueryString('t')){
                        localStorage.clear();
                        let url = totalmanage + '/check?back_url=' + window.location.href;
                        window.location.href = url
                    }else{
                        if (to.path !== '/login') {
                            next('/login');
                            return
                        }else {
                            next();
                        }
                    }
                }
            })
    } else {
        if(getQueryString('t')){
            let url = totalmanage + '/check?back_url=' + window.location.href;
            window.location.href = url
        }else{
            if (to.path !== '/login') {
                next('/login');
                return
            }else {
                next();
            }
        }
    }
    Date.prototype.Format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份
            "D+": this.getDate(), //日
            "h+": this.getHours(), //小时
            "m+": this.getMinutes(), //分
            "s+": this.getSeconds(), //秒
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度
            "S": this.getMilliseconds() //毫秒
        };
        if (/(Y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }
});

Raven.config('https://c6f993725a4a46debfcab7ccb6729c7d@error.jiankanghao.net/62').addPlugin(RavenVue, Vue).install();

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');
